import { DiscountCodeRestrictionDTO } from "~/dto/DiscountCodeRestrictionDTO";
import { DateHelperV3 } from "@/class/DatesV3";

export class DiscountCodeDTO {
  public id = 0;
  public title = "";
  public amount = 0;
  public amountType = "reduction-amount";
  public dateStart = DateHelperV3.generateTodayDate("YYYY-MM-DD");
  public dateEnd = "";
  public code = "";
  public visibility = "no";
  public scopeStatus: "all" | "all-no-products-complementary" | "one-participant" | "one-workshop" | "" = "";
  public justificationStatus: "no" | "yes-booking" | "yes-start" | "" = "";
  public description = "";
  public usageLimit = 999;
  public active = false;
  public restriction = "unrestricted";
  public isDraft = true;
}

export class DiscountCodeExtend extends DiscountCodeDTO {
  public labelRestriction = "";
  public amountCalculated = 0;
  public amountLabel = "";
  public isUsageUnique = false;

  // RESTRICTION PRICE
  public restrictionsWorkshop: DiscountCodeRestrictionDTO[] = [];
  public restrictionsEpass: DiscountCodeRestrictionDTO[] = [];

  constructor(init?: Partial<DiscountCodeExtend>) {
    super();
    Object.assign(this, init);
  }
}
